<template>
  <div>
    <v-row>
      <v-col md="12" v-if="locationsGridShow">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
          >
            <v-toolbar-title>Lokasyonlar</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                autocomplete="off"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
              <RoleProvider slug="LOCATION_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    @click="
                      cleanLocationModalAddUpdateForm(),
                        (locationModalAddUpdateOptions.process = 'add'),
                        (locationModalAddUpdateOptions.title = 'Lokasyon Ekle'),
                        (locationModalAddUpdate = true)
                    "
                    class="py-2 px-3 font-weight-bold"
                    v-if="canItPass"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
            ></ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <CModal
      :title="locationModalAddUpdateOptions.title"
      size="xl"
      :closeOnBackdrop="false"
      class="locationModalAddUpdateFormModal"
      :show.sync="locationModalAddUpdate"
    >
       <!--<CRow class="justify-center">
        <v-col cols="10">
          <span class="font-weight-bold pb-2 mb-2">
            {{ " Dil Seçimi : " }}
          </span>
          <v-btn-toggle
            v-model="btnToggleLanguage"
            borderless
            mandatory
            style="width: 90%"
            active-class="secondary white--text  "
          >
            <v-btn
              small
              style="width: 50%; border-radius: 50px 0 0 50px"
              :class="{ red: !btnToggleLanguage }"
              class="ls-0 text-capitalize font-weight-bold"
            >
              {{ "ingilizce" }}
            </v-btn>
            <v-btn
              small
              style="width: 50%; border-radius: 0 50px 50px 0"
              :class="{ red: btnToggleLanguage }"
              class="ls-0 text-capitalize font-weight-bold"
            >
              {{ "almanca" }}
            </v-btn>
          </v-btn-toggle></v-col
        > 
      </CRow>-->
      <v-form ref="locationForm">
        <CRow>
          <!-- TODO HTML image -->
         
          <CCol>
            <CRow>
              <!-- <CCol sm="4">
                - <b>Lokasyon Slug <small>(TR)</small> : </b>
                <span>{{ locationModalAddUpdateForm.slug }}</span>
              </CCol>
              <CCol sm="4">
                - <b>Lokasyon Slug <small>(EN)</small> : </b>
                <span>{{ locationModalAddUpdateForm.slugEn }}</span>
              </CCol>
              <CCol sm="4" >
                - <b>Lokasyon Slug <small>(DE)</small> : </b>
                <span>{{ locationModalAddUpdateForm.slugDe }}</span>
              </CCol> -->
             

              <CCol sm="4">
                Durum
                <v-autocomplete
                  class="mt-2"
                  outlined
                  dense
                  v-model="locationModalAddUpdateForm.status"
                  :items="locationModalAddUpdateFormStatusOptions"
                  item-text="label"
                  item-val="value"
                />
              </CCol>
              <!-- TODO HTML Şehir -->
              <CCol sm="4">
                Şehir
                <v-autocomplete
                  class="mt-2"
                  v-model="locationModalAddUpdateForm.city"
                  :items="cityNames"
                  outlined
                  :rules="[rules.required]"
                  dense
                ></v-autocomplete>
              </CCol>
              <CCol sm="4">
                IATA Kodu (Havaalanı Kodu)
                <v-text-field
                  class="mt-2"
                  outlined
                  dense
                  v-model="locationModalAddUpdateForm.iataCode"
                />
              </CCol>
              <CCol sm="4">
            Lokasyon Adı Türkçe
            <v-text-field
              outlined
              dense
               class="mb-n6"
              v-model="locationModalAddUpdateForm.locationName"
              :rules="[rules.required]"
            />
           <b>Slug <small>(TR)</small> : </b>
                <span>{{ locationModalAddUpdateForm.slug }}</span>
              
          </CCol>
          <CCol sm="4" >
            Lokasyon Adı İngilizce

            <v-text-field
              outlined
              dense
             class="mb-n6"
              v-model="locationModalAddUpdateForm.locationNameEn"
              :rules="[rules.required]"
            />
            <b>Slug <small>(EN)</small> : </b>
                <span>{{ locationModalAddUpdateForm.slugEn }}</span>
             
          </CCol>
          <CCol sm="4" >
            Lokasyon Adı Almanca
            <v-text-field
              outlined
              dense
     class="mb-n6"
              v-model="locationModalAddUpdateForm.locationNameDe"
              :rules="[rules.required]"
            />
            <b>Slug <small>(DE)</small> : </b>
                <span>{{ locationModalAddUpdateForm.slugDe }}</span>
              
          
          </CCol>
              <!-- TODO HTML Lokasyon -->
            </CRow>
          </CCol>
        </CRow>

      
      </v-form>

      <template #footer>
        <RoleProvider slug="LOCATION_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="locationModalAddUpdateOptions.process == 'add' && canItPass"
              :disabled="locationModalAddUpdateBtnDisable"
              @click="locationAddUpdate(locationModalAddUpdateForm)"
              >Ekle
            </CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="LOCATION_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success text-white"
              v-if="
                locationModalAddUpdateOptions.process == 'update' && canItPass
              "
              :disabled="locationModalAddUpdateBtnDisable"
              @click="locationAddUpdate(locationModalAddUpdateForm)"
            >
              Güncelle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="LOCATION_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              v-if="
                locationModalAddUpdateOptions.process == 'update' && canItPass
              "
              color="danger text-white"
              @click="locationDelete({ _id: locationModalAddUpdateForm._id })"
              >Sil
            </CButton>
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Func from "../../func";
import Vue from "vue";
import ckeditor from "ckeditor4-vue"; // https://ckeditor.com/docs/ckeditor4/latest/guide/dev_vue.html#basic-usage
import { RoleProvider } from "../../provider";
import Multiselect from "vue-multiselect";
import moment from "moment";
import TranslateAWS from "../../components/TranslateAWS.vue";
Vue.use(ckeditor);
axios.defaults.withCredentials = true;

export default {
  name: "LocationSearch",
  data() {
    return {
      loadingAwsIcon: false,
      editorConfig: {},
      multiSelect: {
        campaignOptions: [],
      },

      // AG GID

      ENV_URL_LOCATION: process.env.VUE_APP_CDN_URL_LOCATION,
      locationsGridShow: false,
      cities: [],
      cityNames: [],
      defaultLocationImage: "default_location.png",
      rules: { required: (value) => !!value || "Zorunlu alan." },
      columnDefs: [
        {
          field: "status",
          cellRenderer: (params) => {
            return params.value ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value
              ? { color: "#2eb85c", fontWeight: "bold" }
              : { color: "#e55353", fontWeight: "bold" };
          },
          headerName: "Durum",
          width: 90,
        },
        {
          field: "city",
          headerName: "Şehir",
          width: 120,
        },
        {
          field: "locationName",
          headerName: "Lokasyon Adı",
          width: 200,
        },
        {
          field: "iataCode",
          headerName: "IATA Kodu",
          width: 100,
        },
        {
          field: "slug",
          headerName: "Slug",
          width: 300,
        },
        {
          field: "vendors",
          headerName: "Eşleştirilmiş Lokasyonlar",
          width: 200,
          valueGetter: (params) => params.data.vendors.length,
        },
        {
          field: "vendors",
          headerName: "Eşleştirilmiş Tedarikçiler",
          width: 200,
          valueGetter: (params) =>
            params.data.vendors.map((vendor) => vendor.vendorSlug).join(", "),
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      // LOKASYON ARAMA
      searchLocationFormCollapsed: false,
      searchLocationFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      searchLocationForm: {
        status: "",
        city: "",
        locationName: "",
      },
      // LOKASYON EKLEME GÜNCELLEME MODAL
      locationModalAddUpdate: false, // Car Modal Add Update
      locationModalAddUpdateBtnDisable: false, // Car Modal Add Update button disabled enabled değişkeni
      locationModalAddUpdateOptions: {
        // TODO locationModalAddUpdateOptions
        // Car Modal Add Update değişkenleri
        process: "",
        city: "",
        locationName: "",
        title: "asdasd",
      },
      locationModalAddUpdateFormStatusOptions: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      locationModalAddUpdateFormThemeOptions: [
        {
          value: false,
          label: "Light",
        },
        {
          value: true,
          label: "Dark",
        },
      ],
      btnToggleLanguage: 0,
      locationModalAddUpdateForm: {
        // TODO locationModalAddUpdateForm
        _id: "",
        status: true,
        city: "",
        locationName: "",
        locationNameEn: "",
        locationNameDe: "",
        //theme: false,
        iataCode: "",
        slug: "",
        slugEn: "",
        slugDe: "",
        oldSlug: "",
       // srcImage: "",
        //title: "",
        //files: null,
        //description: "",
        //richText: "",
       // siteTitleTr: "",
        //siteTitleEn: "",
        //siteTitleDe: "",
        //campaignImagesValue: [],
      //   content: {
      //     tr: {
      //       title: "",
      //       description: "",
      //       richText: "",
      //     },
      //     en: {
      //       title: "",
      //       description: "",
      //       richText: "",
      //     },
      //     de: {
      //       title: "",
      //       description: "",
      //       richText: "",
      //     },
      //   },
      //   microData: {
      //     tr: [
      //       {
      //         question: "",
      //         answer: "",
      //       },
      //     ],
      //     en: [
      //       {
      //         question: "",
      //         answer: "",
      //       },
      //     ],
      //     de: [
      //       {
      //         question: "",
      //         answer: "",
      //       },
      //     ],
      //   },
      //   blogPhotos: [],
       },
      searchFilterInput: "",
    };
  },
  components: {
    AgGridVue,
    RoleProvider,
    Multiselect,
    TranslateAWS,
  },
  watch: {
    "locationModalAddUpdateForm.locationName": function (value) {
      return (this.locationModalAddUpdateForm.slug = Func.slugify(value));

      //if (this.locationModalAddUpdateOptions.process === "update")
      //  return (this.locationModalAddUpdateForm.slug = Func.slugify(value));
    },
    "locationModalAddUpdateForm.locationNameEn": function (value) {
            return (this.locationModalAddUpdateForm.slugEn = Func.slugify(value));

     // if (this.locationModalAddUpdateOptions.process === "update")
       // return (this.locationModalAddUpdateForm.slugEn = Func.slugify(value));
    },
    "locationModalAddUpdateForm.locationNameDe": function (value) {
      return (this.locationModalAddUpdateForm.slugDe = Func.slugify(value));

//      if (this.locationModalAddUpdateOptions.process === "update")
  //      return (this.locationModalAddUpdateForm.slugDe = Func.slugify(value));
    },
  },
  methods: {
    // async clickAwsTranslate(where, target) {
    //   const texts = {
    //     ckeditor: {
    //       en: this.locationModalAddUpdateForm.content.en.richText,
    //       tr: this.locationModalAddUpdateForm.content.tr.richText,
    //       de: this.locationModalAddUpdateForm.content.de.richText,

    //       setData: (t, target) => {
    //         this.locationModalAddUpdateForm.content[target].richText = t;
    //       },
    //     },
    //     description: {
    //       en: this.locationModalAddUpdateForm.content.en.description,
    //       tr: this.locationModalAddUpdateForm.content.tr.description,
    //       de: this.locationModalAddUpdateForm.content.de.description,
    //       setData: (t, target) => {
    //         this.locationModalAddUpdateForm.content[target].description = t;
    //       },
    //     },
    //     title: {
    //       en: this.locationModalAddUpdateForm.content.en.title,
    //       tr: this.locationModalAddUpdateForm.content.tr.title,
    //       de: this.locationModalAddUpdateForm.content.de.title,
    //       setData: (t, target) => {
    //         this.locationModalAddUpdateForm.content[target].title = t;
    //       },
    //     },
    //     microData: {
    //       en: this.locationModalAddUpdateForm.microData.en,
    //       tr: this.locationModalAddUpdateForm.microData.tr,
    //       de: this.locationModalAddUpdateForm.microData.de,
    //       setData: (t, target) => {
    //         this.locationModalAddUpdateForm.microData[target] = t;
    //       },
    //     },
    //     locationname: {
    //       tr: this.locationModalAddUpdateForm.locationName,
    //       en: this.locationModalAddUpdateForm.locationNameEn,
    //       de: this.locationModalAddUpdateForm.locationNameDe,
    //       setData: (t, target) => {
    //         this.locationModalAddUpdateForm[
    //           `locationName${target.charAt(0).toUpperCase()}${target.slice(1)}`
    //         ] = t;
    //       },
    //     },
    //   };
    //   try {
    //   //  const source = "tr";
    //  //   const sourceTextOrObject = texts[where][source];
    //   //  this.loadingAwsIcon = true;

    //     // if (typeof sourceTextOrObject == "object") {
    //     //   let translations = sourceTextOrObject.map(async (item) => ({
    //     //     question: await this.awsTranslate(item.question, source, target),
    //     //     answer: await this.awsTranslate(item.answer, source, target),
    //     //   }));
    //     //   translations = await Promise.all(translations);
    //     //   texts[where].setData(translations, target);
    //     // } else {
    //     //   const text = await this.awsTranslate(
    //     //     sourceTextOrObject,
    //     //     source,
    //     //     target
    //     //   );
    //     //   texts[where].setData(text, target);
    //     // }
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     this.loadingAwsIcon = false;
    //   }
    // },
    // async awsTranslate(text, source, target) {
    //   try {
    //     const { data } = await axios.post(
    //       process.env.VUE_APP_API_URL + "admin/aws-translate",
    //       {
    //         text,
    //         source,
    //         target,
    //       }
    //     );

    //     return data || "error";
    //   } catch (error) {
    //     console.log(error.response);
    //   }
    // },

    // blogFilesInputDelete(index) {
    //   this.locationModalAddUpdateForm.blogPhotos.splice(index, 1);
    // },
    // blogFilesInputChange() {
    //   const _this = this;
    //   const files = this.$refs.blogFiles.files;
    //   for (let index = 0; index < files.length; index++) {
    //     const fileImage = files[index];
    //     if (fileImage.size > 1048576) {
    //       Vue.swal({
    //         icon: "error",
    //         title: "Lokasyon Ekle - Resim",
    //         html: "Maksimum 1 MB resim yükleyebilirsiniz!",
    //         confirmButtonText: "Tamam",
    //       });
    //     } else {
    //       if (
    //         fileImage.type === "image/png" ||
    //         fileImage.type === "image/jpg" ||
    //         fileImage.type === "image/jpeg"
    //       ) {
    //         let reader = new FileReader();

    //         reader.onload = function (e) {
    //           _this.locationModalAddUpdateForm.blogPhotos.push(e.target.result);
    //         };
    //         reader.readAsDataURL(fileImage);
    //       } else {
    //         Vue.swal({
    //           icon: "error",
    //           title: "Lokasyon Ekle - Resim",
    //           html: "Yalnızca png, jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
    //           confirmButtonText: "Tamam",
    //         });
    //       }
    //     }
    //   }
    // },
    // microDataAdd(lang) {
    //   var microData = {
    //     question: "",
    //     answer: "",
    //   };
    //   this.locationModalAddUpdateForm.microData[lang].push(microData);
    // },

    // microDataDelete(val, lang) {
    //   Vue.swal
    //     .fire({
    //       icon: "question",
    //       text: "Silmek istiyor musunuz?",
    //       showCancelButton: true,
    //       confirmButtonText: "Evet",
    //       cancelButtonText: "Hayır",
    //       showCloseButton: true,
    //     })
    //     .then(async (result) => {
    //       if (result.isConfirmed) {
    //         var item =
    //           this.locationModalAddUpdateForm.microData[lang].indexOf(val);

    //         this.locationModalAddUpdateForm.microData[lang].splice(item, 1);
    //       }
    //     });
    // },

    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    // clickImageUpload() {
    //   // TODO clickImageUpload
    //   document.getElementById("inputLocationImageFile").click();
    // },

    // clickImageDelete() {
    //   // TODO clickImageDelete
    //   this.locationModalAddUpdateForm.srcImage =
    //     this.ENV_URL_LOCATION + this.defaultLocationImage;
    //  // this.locationModalAddUpdateForm.files = null;
    //   //document.getElementById("inputLocationImageFile").value = "";
    // },

    // selectImage() {
    //   // TODO selectImage
    //   const _this = this;
    //   const fileImage = this.$refs.files.files[0];

    //   if (fileImage.size > 1048576) {
    //     Vue.swal({
    //       icon: "error",
    //       title: "Lokasyon Ekle - Resim",
    //       html: "Maksimum 1 MB resim yükleyebilirsiniz!",
    //       confirmButtonText: "Tamam",
    //     });
    //   } else {
    //     if (
    //       fileImage.type === "image/png" ||
    //       fileImage.type === "image/jpg" ||
    //       fileImage.type === "image/jpeg"
    //     ) {
    //       let reader = new FileReader();

    //       reader.onload = function (e) {
    //         _this.locationModalAddUpdateForm.srcImage = e.target.result;
    //         _this.locationModalAddUpdateForm.files = fileImage;
    //       };
    //       reader.readAsDataURL(fileImage);
    //     } else {
    //       Vue.swal({
    //         icon: "error",
    //         title: "Lokasyon Ekle - Resim",
    //         html: "Yalnızca png, jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
    //         confirmButtonText: "Tamam",
    //       });
    //     }
    //   }
    // },

    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    cleanLocationModalAddUpdateForm() {
      // TODO cleanLocationModalAddUpdateForm
      this.locationModalAddUpdateForm._id = "";
      this.locationModalAddUpdateForm.status = true;
      this.locationModalAddUpdateForm.city = "";
      this.locationModalAddUpdateForm.locationName = "";
      this.locationModalAddUpdateForm.locationNameEn = "";
      this.locationModalAddUpdateForm.locationNameDe = "";
     // this.locationModalAddUpdateForm.theme = false;
     // this.locationModalAddUpdateForm.blogPhotos = [];
     // this.$refs.blogFiles.value = "";
      this.locationModalAddUpdateForm.iataCode = "";
    //  this.locationModalAddUpdateForm.title = "";
    //  this.locationModalAddUpdateForm.description = "";
     // this.locationModalAddUpdateForm.srcImage = "";
     // this.locationModalAddUpdateForm.siteTitleTr = "";
     // this.locationModalAddUpdateForm.siteTitleEn = "";
     // this.locationModalAddUpdateForm.siteTitleDe = "";
      this.locationModalAddUpdateForm.slug = "";
      this.locationModalAddUpdateForm.slugEn = "";
      this.locationModalAddUpdateForm.slugDe = "";
      this.locationModalAddUpdateForm.oldSlug = "";
   //   this.locationModalAddUpdateForm.richText = "";

      // this.locationModalAddUpdateForm.content = {
      //   tr: {
      //     title: "",
      //     description: "",
      //     richText: "",
      //   },

      //   en: {
      //     title: "",
      //     description: "",
      //     richText: "",
      //   },
      //   de: {
      //     title: "",
      //     description: "",
      //     richText: "",
      //   },
      // };
      // this.locationModalAddUpdateForm.microData = {
      //   tr: [
      //     {
      //       question: "",
      //       answer: "",
      //     },
      //   ],
      //   en: [
      //     {
      //       question: "",
      //       answer: "",
      //     },
      //   ],
      //   de: [
      //     {
      //       question: "",
      //       answer: "",
      //     },
      //   ],
      // };
      // this.locationModalAddUpdateForm.campaignImagesValue = [];
    },

    getCities() {
      this.$Progress.start();
      axios
        .get(process.env.VUE_APP_API_URL + "citylocation/sitemap")
        .then((response) => {
          this.cities = response.data;
          this.cities.forEach((city) => {
            this.cityNames.push(city.city);
          });
        });
    },

    searchLocation() {
      this.$Progress.start();
      axios
        .get(process.env.VUE_APP_API_URL + "admin/location", {
          params: this.searchLocationForm,
        })
        .then((response) => {
          this.$Progress.finish();
          this.rowData = response.data.locationList;
          this.searchLocationFormCollapsed = false;
          this.locationsGridShow = true;
        });
    },

    async validateForm() {
      const enValue = this.locationModalAddUpdateForm.locationNameEn;
      const deValue = this.locationModalAddUpdateForm.locationNameDe;
      const isLocationFormValid = await this.$refs.locationForm.validate();

      if (!(enValue && deValue) || !isLocationFormValid) {
        return false;
      } else {
        return true;
      }
    },

    async locationAddUpdate(params) {
      var formData = new FormData(); // TODO Form data

      // if (
      //   this.locationModalAddUpdateForm.files !== null &&
      //   this.locationModalAddUpdateForm.files.size > 0
      // )
      //   formData.append("image", this.locationModalAddUpdateForm.files);
      // else if (
      //   this.locationModalAddUpdateForm.srcImage ===
      //   this.ENV_URL_LOCATION + this.defaultLocationImage
      // )
      //   formData.append("image", this.defaultLocationImage);

      if (this.locationModalAddUpdateOptions.process === "update")
        formData.append("_id", params._id);
      // formData.append("content", JSON.stringify(params.content));
      // formData.append("microData", JSON.stringify(params.microData));

      formData.append("status", params.status);
      formData.append("city", params.city);
      formData.append("locationName", params.locationName);
      formData.append("locationNameEn", params.locationNameEn);
      formData.append("locationNameDe", params.locationNameDe);
     //  formData.append("theme", params.theme);
      formData.append("iataCode", params.iataCode);

      // slug oluştururken yaşanan sorun sebebiyle lokasyon girilemiyor. bu şekilde bir çözüm ürettim
      // if (this.locationModalAddUpdateOptions.process != "update") {
      //   var _slug = Func.slugify(this.locationModalAddUpdateForm.locationName);
      //   var _slugEn = Func.slugify(
      //     this.locationModalAddUpdateForm.locationNameEn
      //   );
      //   var _slugEn = Func.slugify(
      //     this.locationModalAddUpdateForm.locationNameEn
      //   );
      //   var _slugDe = Func.slugify(
      //     this.locationModalAddUpdateForm.locationNameDe
      //   );
      //   formData.append("slug", _slug);
      //   formData.append("slugEn", _slugEn);
      //   formData.append("slugDe", _slugDe);
      //   formData.append("oldSlug", params.oldSlug);
      // }

      // //Lokasyon güncelleme yaparken DE slug ekleme
      // if (
      //   this.locationModalAddUpdateOptions.process == "update" &&
      //   this.locationModalAddUpdateForm.slugDe
      // ) {
      //   var _slug = Func.slugify(this.locationModalAddUpdateForm.locationName);
      //   var _slugEn = Func.slugify(
      //     this.locationModalAddUpdateForm.locationNameEn
      //   );
      //   var _slugEn = Func.slugify(
      //     this.locationModalAddUpdateForm.locationNameEn
      //   );
      //   var _slugDe = Func.slugify(
      //     this.locationModalAddUpdateForm.locationNameDe
      //   );
      //   formData.append("slug", _slug);
      //   formData.append("slugEn", _slugEn);
      //   formData.append("slugDe", _slugDe);
      //   formData.append("oldSlug", params.oldSlug);
      // }

      // formData.append(
      //   "campaignImagesValue",
      //   JSON.stringify(params.campaignImagesValue)
      // );
      // formData.append("siteTitleTr", params.siteTitleTr);
      // formData.append("siteTitleEn", params.siteTitleEn);
      // formData.append("siteTitleDe", params.siteTitleDe);

      // params.blogPhotos.forEach((item) => {
      //   if (item.includes(this.ENV_URL_LOCATION))
      //     item = item.replace(this.ENV_URL_LOCATION, "");
      //   formData.append("blogPhotos", item);
      // });
      formData.append("slug", this.locationModalAddUpdateForm.slug);
        formData.append("slugEn", this.locationModalAddUpdateForm.slugEn);
        formData.append("slugDe", this.locationModalAddUpdateForm.slugDe);
      this.$Progress.start();
      this.locationModalAddUpdateBtnDisable = true; // Modal'daki post button'u disable ettik.

      if (this.locationModalAddUpdateOptions.process === "add") {
        const validate = await this.validateForm();

        if (!validate) {
          Vue.swal({
            icon: "error",
            title: "Lokasyon Ekle",
            html: "Eksik Alan Mevcut",
            confirmButtonText: "Tamam",
          });
          this.locationModalAddUpdateBtnDisable = false;
          return;
        }
        axios
          .post(process.env.VUE_APP_API_URL + "admin/location", formData)
          .then((response) => {
            this.$Progress.finish();
            this.locationModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

            if (response.data.result === "success") {
              if (this.locationsGridShow) this.searchLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
              this.locationModalAddUpdate = false; // Modal'ı kapattık
            }

            Vue.swal({
              icon: response.data.result,
              title: "Lokasyon Ekle",
              html: response.data.message,
              confirmButtonText: "Tamam",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.locationModalAddUpdateOptions.process === "update") {
        axios
          .put(process.env.VUE_APP_API_URL + "admin/location", formData)
          .then((response) => {
            this.$Progress.finish();
            this.locationModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

            if (response.data.result === "success") {
              if (this.locationsGridShow) this.searchLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
              this.locationModalAddUpdate = false; // Modal'ı kapattık
            }

            Vue.swal({
              icon: response.data.result,
              title: "Lokasyon Düzenle",
              html: response.data.message,
              confirmButtonText: "Tamam",
            });
          });
      }
    },

    locationDelete(params) {
      this.$Progress.start();
      axios
        .delete(process.env.VUE_APP_API_URL + "admin/location/" + params._id)
        .then((response) => {
          this.$Progress.finish();
          this.searchLocation();

          Vue.swal({
            icon: response.data.result,
            title: "Lokasyon Sil",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        })
        .finally(() => {
          this.locationModalAddUpdate = false;
        });
    },

    onRowDoubleClicked(params) {
      var _this = this;
      _this.cleanLocationModalAddUpdateForm();
      _this.locationModalAddUpdateOptions.process = "update";
      _this.locationModalAddUpdateOptions.title = "Lokasyonu Güncelle";
      _this.locationModalAddUpdateForm._id = params.node.data._id;

      // _this.locationModalAddUpdateForm.title = params.node.data.title;
      // _this.locationModalAddUpdateForm.schendler = params.node.data.schendler;
      // _this.locationModalAddUpdateForm.description =
      //   params.node.data.description;
      // _this.locationModalAddUpdateForm.srcImage =
      //   _this.ENV_URL_LOCATION + params.node.data.image;
      // _this.locationModalAddUpdateForm.blogPhotos =
      //   params.node.data?.blogPhotos?.map(
      //     (item) => _this.ENV_URL_LOCATION + item
      //   ) || [];

      // _this.locationModalAddUpdateForm.files = null;
      // _this.locationModalAddUpdateForm.campaignImagesValue =
      //   params.node.data.campaignImagesValue;

      _this.locationModalAddUpdateForm.status = params.node.data.status;
      _this.locationModalAddUpdateForm.city = params.node.data.city;
      _this.locationModalAddUpdateForm.locationName =
        params.node.data.locationName;
      _this.locationModalAddUpdateForm.locationNameEn =
        params.node.data.locationNameEn;
      _this.locationModalAddUpdateForm.locationNameDe =
        params.node.data.locationNameDe;
     // _this.locationModalAddUpdateForm.theme = params.node.data.theme;
      _this.locationModalAddUpdateForm.iataCode = params.node.data.iataCode;
      // _this.locationModalAddUpdateForm.siteTitleTr =
      //   params.node.data.siteTitleTr;
      // _this.locationModalAddUpdateForm.siteTitleEn =
      //   params.node.data.siteTitleEn;
      // _this.locationModalAddUpdateForm.siteTitleDe =
      //   params?.node?.data?.siteTitleDe;
      _this.locationModalAddUpdateForm.slug = params.node.data.slug;
      _this.locationModalAddUpdateForm.slugEn = params.node.data.slugEn;
      _this.locationModalAddUpdateForm.slugDe = params.node.data.slugDe;
      //_this.locationModalAddUpdateForm.richText = params.node.data.richText;

      _this.locationModalAddUpdateForm.oldSlug = params.node.data.slug;
      _this.locationModalAddUpdate = true;

      // if (params.node.data.microData) {
      //   _this.locationModalAddUpdateForm.microData = params.node.data.microData;
      // }
      // if (params.node.data.content) {
      //   _this.locationModalAddUpdateForm.content.tr =
      //     params.node.data.content.tr;
      //   _this.locationModalAddUpdateForm.content.en =
      //     params.node.data.content.en;
      //   _this.locationModalAddUpdateForm.content.de =
      //     params.node.data.content.de;

      //   /**
      //   _this.locationModalAddUpdateForm.content.de.title = params?.node?.data?.content?.de?.title || "test";
      //   _this.locationModalAddUpdateForm.content.de.description = params?.node?.data?.content?.de?.description || "test";
      //   */
      // }
    },
  },
  created: function () {
    this.getCities();
    this.searchLocation();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.search-schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-files-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.blog-files-header label:nth-child(2) {
  border: 1px solid rgba(187, 186, 186, 0.406);
  border-radius: 5px;
  padding: 5px 20px;
  cursor: pointer;
  margin-left: 1rem;
}

.blog-photo {
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.blog-photo-wr {
  background-color: #eaeaea !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 200px;
  width: 300px;
  position: relative;
}

.blog-photo-wr svg {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px 5px !important;
  cursor: pointer !important;
}

.blog-photo-wr svg path {
  color: white;
}

.not-found-photo {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 200px;
}
.red {
  background-color: red !important;
  color: white !important;
}
</style>
